// Customizable Area Start
import React from "react";
import {
    Grid,
} from "@mui/material";
import {
    image
} from "./assets";
import LandingPageController, {
    Props,
} from "./LandingPageController";

export default class WellSpring extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        return (
            <Grid
                container
                sx={{
                    height: "100vh",
                    backgroundColor: "lightgray",
                    padding: "10px",
                    display: "flex",
                    gap: "10px",
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={5.92}
                    sx={{
                        position: "relative",
                        backgroundColor: "white",
                        borderRadius: "20px",
                    }}
                >
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={5.94}
                    lg={5.97}
                    sx={{
                        background: `url(${image.default})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        display: { xs: "none", md: "flex" },
                        flexDirection: "column",
                        justifyContent: "space-between",
                        borderRadius: "20px",
                        position: "relative",
                        padding: "50px",
                    }}
                >
                </Grid>
            </Grid>
        );
    }
}
// Customizable Area End