import React from "react";

// Customizable Area Start
import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  Typography,
  Checkbox,
  Box,
  IconButton,
  FormControl,
  Modal,
} from "@mui/material";

import {
  imgVisbility,
  imgVisbilityOff,
  topBannerImg,
  closeBtn,
  bottomBannerImg,
  rightImage,
  notation,
  arrow,
} from "./assets";
import { Formik, Field, Form } from "formik";
import { StyleSheet } from "react-native";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderLogin=()=>{
    return(
      (
        <>
          <Typography
            variant="h3"
            sx={{
              color: "#00A7D1",
              fontWeight: "400",
              lineHeight: "48px",
              mb: 2,
              textAlign: "left",
            }}
          >
            Let’s get started!
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#252525",
              fontWeight: "400",
              lineHeight: "32px",
              mb: 4,
              textAlign: "left",
            }}
          >
            Log in to your account and explore our app
          </Typography>
          <Formik
            initialValues={{
              username: this.state.username,
              password: this.state.password,
              termsAndCondition: this.state.termsAndCondition,
            }}
            validationSchema={this.validationSchema}
            onSubmit={(values: any) => {
              if (values.termsAndCondition) {
                this.setState({
                  username: values.username,
                  password: values.password,
                  termsAndCondition: values.termsAndCondition,
                });
                this.handleLoginApi()
              }
            }}
            data-test-id="formikform"
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              touched,
            }) => (
              <Form onSubmit={handleSubmit} data-test-id="login-form">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      fullWidth
                      name="username"
                      label="Username"
                      variant="standard"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                      data-test-id="input-username"
                      helperText={
                        touched.username && !values.username ? (
                          <span
                            style={{ color: "red", fontSize: "12px" }}
                            data-test-id="error-username"
                          >
                            Username is required
                          </span>
                        ) : null
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth variant="standard">
                      <Field
                        as={TextField}
                        label="Password"
                        name="password"
                        type={
                          this.state.showPassword ? "text" : "password"
                        }
                        variant="standard"
                        data-test-id="input-password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              data-test-id="password-toggle"
                            >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickPasswordShow}
                                data-test-id="toggle-password-visibility"
                              >
                                {this.state.showPassword ? (
                                  <img
                                    src={imgVisbility.default}
                                    alt="Hide"
                                    style={{ width: "24px" }}
                                    data-test-id="img-visibility"
                                  />
                                ) : (
                                  <img
                                    src={imgVisbilityOff.default}
                                    alt="Show"
                                    style={{ width: "24px" }}
                                    data-test-id="img-visibility-off"
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        
                        helperText={
                          touched.password && !values.password ? (
                            <span
                              style={{ color: "red", fontSize: "12px" }}
                              data-test-id="error-password"
                            >
                              Password is required
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 3,
                    mt: 2,
                    textAlign: "left",
                  }}
                  data-test-id="terms-checkbox-container"
                >
                  <Checkbox
                    checked={values.termsAndCondition}
                    onChange={handleChange}
                    name="termsAndCondition"
                    data-test-id="checkbox-terms"
                    sx={{ paddingLeft: "0px" }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#252525",
                      marginTop: "4px",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    By signing up, you agree with our{" "}
                    <span
                      style={{
                        borderBottom: "1px solid black",
                        cursor: "pointer",
                      }}
                      onClick={this.handleOpen}
                      data-test-id="terms-link"
                    >
                      Terms & Conditions
                    </span>
                    <Modal
                      open={this.state.showAndHideTermsAndCondition}
                      onClose={this.handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      data-test-id="terms-modal"
                    >
                      <Box
                        sx={{
                          position: "absolute" as "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "50%",
                          bgcolor: "#FFFFFF",
                          boxShadow: 24,
                          p: 4,
                          height: "70%",
                          borderRadius: "24px",
                          overflow: "hidden",
                        }}
                        data-test-id="modal-terms"
                      >
                        <Box sx={{ position: "relative" }}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h1"
                            sx={{
                              fontSize: "clamp(35px, 4vw, 48px)",
                              color: "#00A7D1",
                              fontWeight: 600,
                            }}
                            data-test-id="modal-title"
                          >
                            Terms & Conditions
                          </Typography>
                          <img
                            src={closeBtn.default}
                            alt="Close"
                            style={{
                              width: "15px",
                              position: "absolute",
                              top: "0",
                              right: "0",
                            }}
                            onClick={this.handleClose}
                            data-test-id="modal-close-btn"
                          />
                        </Box>
                        <Box
                          sx={{ height: "80%", overflow: "scroll" }}
                          data-test-id="modal-content"
                        >
                        
                        </Box>
                      </Box>
                    </Modal>
                  </Typography>
                </Box>

                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    padding: "10px",
                    borderRadius: "12px",
                    background:
                      "linear-gradient(91.48deg, #BACA08 0%, #00A7D1 100%)",
                    textTransform: "none",
                    fontSize: "18px",
                    fontWeight: 700,
                    lineHeight: "24px",
                  }}
                  type="submit"
                  data-test-id="login-submit-btn"
                >
                  Log in
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )
    )
  }
  renderContactUs=()=>{
    return(
      <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: "48px",
                      lineHeight: "48px",
                      fontWeight: 600,
                      color: "#00A7D1",
                      textAlign: "left",
                      margin: "4px 0px",
                    }}
                  >
                    Contact us!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Username"
                    variant="standard"
                    sx={{ mb: 3 }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ fontWeight: 400, fontSize: "40px" }}>
                  <TextField
                    label="Write description here.."
                    multiline
                    rows={4}
                    variant="standard"
                    fullWidth
                    sx={{ fontSize: "40px" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      padding: "10px",
                      borderRadius: "12px",
                      mt: "10px",
                      background:
                        "linear-gradient(91.48deg, #BACA08 0%, #00A7D1 100%)",
                      fontSize: "18px",
                      fontWeight: 700,
                      lineHeight: "24px",
                      textTransform: "none",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
    )
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Grid
      container
      sx={{
        height: "100vh",
        backgroundColor: "lightgray",
        padding: "10px",
        display: "flex",
        gap: "10px",
      }}
    >
      <Grid
        item
        xs={12}
        md={5.92}
        sx={{
          position: "relative",
          backgroundColor: "white",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            px: 4,
          }}
        >
          <Box
            component="img"
            src={topBannerImg.default}
            alt="Logo"
            sx={{ position: "absolute", top: 0, left: 0 }}
          />
          <Box
            component="img"
            src={bottomBannerImg.default}
            alt="Logo"
            sx={{ position: "absolute", bottom: 0, right: 0 }}
          />
          {this.state.showContactUs && (
            <Box onClick={this.handleContactUsClose} data-test-id="back-btn">
              <Box
                component="img"
                src={arrow.default}
                alt="Logo"
                sx={{ position: "absolute", top: 50, left: 30 }}
              />
              <Typography
                sx={{
                  position: "absolute",
                  top: 45,
                  left: 52,
                  cursor: "pointer",
                }}
              >
                Back
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              width: "100%",
              zIndex: 1,
              textAlign: "center",
            }}
          >
            {!this.state.showContactUs ? this.renderLogin()
             : 
             this.renderContactUs()
             }
          </Box>
          {!this.state.showContactUs && (
            <Box
              sx={{
                position: "absolute",
                bottom: 40,
                left: 32,
                width: "100%",
                textAlign: "left",
              }}
            >
              <Typography
                variant="body2"
                align="left"
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: "#252525",
                }}
              >
                Looking for help:{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: 500,
                  }}
                  onClick={()=>this.handleContactUsOpen()}
                  data-test-id="contact-us"
                >
                  Contact us
                </span>
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>

      
      <Grid
        item
        xs={12}
        md={5.94}
        lg={5.97}
        sx={{
          background: `url(${rightImage.default})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: "20px",
          position: "relative",
          padding: "50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            color: "#EBFF00",
            fontSize: "64px",
            fontWeight: "700",
            lineHeight: "72px",
          }}
        >
          <img
            src={notation.default}
            alt="notation"
            style={{ width: "40px", marginBottom: "4px" }}
          />
          <span>It's about</span>
          <span>progress, not</span>
          <span>perfection</span>
        </Box>
        <Box sx={{ fontSize: "40px", color: "#ffffff", fontWeight: 700 }}>
          <span style={{ color: "#EBFF00" }}>Well</span>Spring
        </Box>
      </Grid>
    </Grid>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
